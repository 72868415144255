import React, { useState } from "react";

function Contact() {
  const [formSubmitted, setFormSubmitted] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();

    const formData = {
      Name: event.target.elements.name.value,
      Email: event.target.elements.email.value,
      Phone: event.target.elements.phone.value,
      Time: event.target.elements.time.value,
      Comments: event.target.elements.comments.value,
    };

    fetch("https://api.apispreadsheets.com/data/TUWzOOEVLn6oGC6J/", {
      method: "POST",
      headers: {
        accessKey: "bcbd9423bc7d331c383297d2f72fb5b4",
        secretKey: "4f8112f86ce2368d765c5bcbc77da14c",
      },
      body: JSON.stringify({ data: formData }),
    })
      .then((res) => {
        if (res.status === 201) {
          setFormSubmitted(true);
        } else {
          console.error("Form submission failed.");
        }
      })
      .catch((error) => {
        console.error("Form submission error:", error);
      });
  }

  return (
    <div className="contact" id="contact">
      <h1 className="hh5">Let's Get Started</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-item">
          <label className="fixed-label-size">Name</label>
          <input name="name" />
        </div>
        <div className="form-item">
          <label className="fixed-label-size">E-mail</label>
          <input name="email" />
        </div>
        <div className="form-item">
          <label className="fixed-label-size">Phone</label>
          <input name="phone" />
        </div>

        <div className="form-item form-item-hopes">
          <label>Best Time to Get in Touch</label>
          <input name="time" />
        </div>

        <div className="form-item form-item-hopes">
          <label>What should we know about you?</label>
          <textarea name="comments" />
        </div>

        <div className="submit-button">
          <button className="submit-button-text" type="submit">
            Submit
          </button>
        </div>
      </form>

      {formSubmitted && (
        <div className="thank-you">
          <h3>I have received your message and will get in touch soon.</h3>
          <h3>Thank you!</h3>
        </div>
      )}
    </div>
  );
}

export default Contact;
