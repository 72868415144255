import React from "react";
import ScrollIntoView from "react-scroll-into-view";

function Nav() {
  return (
    <div className="nav-bar">
      <ScrollIntoView selector="#home">
        <div className="nav-item">Home</div>
      </ScrollIntoView>
      <ScrollIntoView selector="#mission">
        <div className="nav-item">Mission</div>
      </ScrollIntoView>
      <ScrollIntoView selector="#tours">
        <div className="nav-item">Tours</div>
      </ScrollIntoView>
      <ScrollIntoView selector="#contact">
        <div className="nav-item">Contact</div>
      </ScrollIntoView>
    </div>
  );
}

export default Nav;
