import React, { useEffect, useState } from "react";
import Header from "./sections/header";
import Footer from "./sections/footer";
import Mission from "./sections/mission";
import Tours from "./sections/tours";
import Contact from "./sections/contact";
import ScrollIntoView from "react-scroll-into-view";
import "./App.css";

import Foreground from "./images/happyBasinsBackgroundBottom.png";

function App() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const foregroundParallaxClass =
    scrollY > 0 ? "foreground parallax" : "foreground";

  return (
    <div className="App">
      <Header />
      <div className="container">
        <ScrollIntoView selector="#contact">
          <button className="ready-button" to="#contact">
            {" "}
            I'm Ready
          </button>
        </ScrollIntoView>
        <img
          src={Foreground}
          className={foregroundParallaxClass}
          alt="jungle landscape with trees surrounding a long river"
        />
        <Mission />
        <Tours />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;
