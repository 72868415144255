import Nav from "./Nav";
import Basin from "../images/hanging-emerald.png";

function Header() {
  return (
    <div className="App-header" id="home">
      <div className="header-box">
        <img
          src={Basin}
          className="the-snake"
          alt="Green emerald tree boa coilet around the border of the website"
        />
        <Nav />
        <h1>Happy Basins</h1>
        <h3>Suriname Emerald Tours</h3>
        <h4>The Only Tour that Specializes in Emerald Tree Boa Encounters</h4>
      </div>
    </div>
  );
}

export default Header;
