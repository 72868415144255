import React from "react";
import Dates from "./dates";
import TourPic1 from "../images/trip/amy-and-bob-hold-many.jpg";
import TourPic2 from "../images/trip/arm-out-group.jpg";
import TourPic3 from "../images/trip/boa-in-the-dark.jpg";
import TourPic4 from "../images/trip/everyone-holds-a-snake-boys.jpeg";
import TourPic5 from "../images/trip/everyone-holds-a-snake-girls.jpeg.jpg";
import TourPic6 from "../images/trip/house-on-the-river.jpg";
import TourPic7 from "../images/trip/jungle-beds.jpg";
import TourPic8 from "../images/trip/monkey-around.jpg";
import TourPic9 from "../images/trip/on-the-amy.jpeg";
import TourPic10 from "../images/trip/sleeping-indoors.jpg";
import TourPic11 from "../images/trip/split-legs-bob.jpeg";
import TourPic12 from "../images/trip/thumbs-up-group.jpg";
import TourPic13 from "../images/trip/we-were-girls-together.jpg";
import TourPic14 from "../images/trip/amy-holds-many.png";
import TourPic15 from "../images/trip/muddy-truck.jpg";
import TourPic16 from "../images/trip/topsy-turvy.jpg";

function Tours() {
  return (
    <div className="tours" id="tours">
      <h2>Upcoming Tours</h2>
      <Dates />
      <div className="example-tour">
        <h2>What does an Emerald Tours trip look like?</h2>
        <div className="tour-grid">
          <div className="text-block">
            <p>
              Fly Miami to Paramaribo with a short stop in Georgetown where you
              don’t leave the plane.{" "}
            </p>
            <p>
              Up to three guests meet in Miami and fly to Paramaribo with a
              quick 45-minute stop in Georgetown, Guyana. Depending on the
              travel schedule, we spend one or two nights exploring Paramaribo
              before traveling to Apoera. Transportation and travel schedules
              depend on your trip budget.
            </p>
            <p>
              Arrive in Apoera and spend two nights exploring the surrounding
              Apoera jungle with local hunters and trappers. We use local
              wild-caught snakes as ambassadors to educate the locals about the
              differences between nonvenomous and venomous snakes before setting
              the snake back in the wild.
            </p>
            <p>
              Our Aporea cook is a local sensation with authentic dishes and
              pastries. Mark, with our emerald Tours river guides, Tony and
              Kenny, take us upriver to search for animals in the Suriname
              rainforest. Mark and Tony specialize in arboreal herping, finding
              tree snakes, of which my favorite is the emerald tree boa. Kenny
              is just an overall badass and does everything native.
            </p>
            <p>
              The Courantyne River, boarded by Suriname on the east and Guyana
              on the west, will take us from Apoera to the Cow Falls area. We
              spend six nights on the river searching for animals, sleeping in
              hammocks, and living on what the jungle provides. We take some
              provisions but depending on the season, our protein is fresh
              wild-caught game, fowl, and fish.
            </p>
            <p>
              We set up our hammock camp at a beautiful rocky point on the
              river. Rocky Point has plenty of space for private hammock areas,
              cooking and eating areas, and lots of space and trails to explore.
            </p>
            <p>
              We search for animals each night during the coolest and darkest
              part of the new moon night. Our mission is to find emerald
              tre-boas and colored Amazon tree boas in highly trafficked areas.
              We relocate the high-valued snakes to resource-rich places deep in
              the forest where we hope no trappers will travel at night.
            </p>
            <p>
              During our night searches, we’ve seen sloths and cayman, a jaguar
              and a tapir, capybara, other snake species, and many other
              animals. We also look for animals at sunrise when we hear howler
              monkeys filling the morning silence. We've even glimpsed the giant
              river otters and miniature foxes.
            </p>
            <p>
              On our final night on the river, we search for animals on our way
              home and arrive back in Apoera with our modern accommodations so
              we can relax We stay in Aporea for one or two nights, when we have
              a departure party with our beautiful friends in Apoera. When we
              head back to Paramaribo we will spend one or two days visiting
              friends and exploring the city.
            </p>
            <p>
              Suriname is a tough travel where transportation and lodging aren't
              always easy to find. When you book an Emerald Tour with Happy
              Basins, you land in Miami then we take it from there. From Miami
              and back again, your coordinator, drivers, and guides take care of
              your travel, so all you have to do is enjoy.
            </p>
            <a
              target="_blank"
              href="https://www.amazon.com/hz/wishlist/ls/1CZ1Z69WC1Z27?ref_=wl_share&_encoding=UTF8&tag=happybasins-20&linkCode=ur2&linkId=e89d848e253f5705fa5e07213fc815a1&camp=1789&creative=9325"
            >
              Emerald Tours Packing List
            </a>
          </div>
          <img src={TourPic1} className="TourPic1 tourpics" alt=""></img>
          <img src={TourPic2} className="TourPic2 tourpics" alt=""></img>
          <img src={TourPic3} className="TourPic3 tourpics" alt=""></img>
          <img src={TourPic4} className="TourPic4 tourpics" alt=""></img>
          <img src={TourPic5} className="TourPic5 tourpics" alt=""></img>
          <img src={TourPic6} className="TourPic6 tourpics" alt=""></img>
          <img src={TourPic7} className="TourPic7 tourpics" alt=""></img>
          <img src={TourPic8} className="TourPic8 tourpics" alt=""></img>
          <img src={TourPic9} className="TourPic9 tourpics" alt=""></img>
          <img src={TourPic10} className="TourPic10 tourpics" alt=""></img>
          <img src={TourPic11} className="TourPic11 tourpics" alt=""></img>
          <img src={TourPic12} className="TourPic12 tourpics" alt=""></img>
          <img src={TourPic13} className="TourPic13 tourpics" alt=""></img>
          <img src={TourPic14} className="TourPic14 tourpics" alt=""></img>
          <img src={TourPic15} className="TourPic13 tourpics" alt=""></img>
          <img src={TourPic16} className="TourPic14 tourpics" alt=""></img>
        </div>
      </div>
    </div>
  );
}

export default Tours;
