import React from "react";

function Mission() {
  return (
    <div className="mission-box" id="mission">
      <div className="mission">
        <h2 className="offset-title">Mission</h2>
        <p>
          At Happy Basins Suriname Emerald Tours, our mission is to unlock the
          wonders of the Suriname rainforest for explorers, instilling a deep
          appreciation for its pristine beauty, rich cultural heritage, and
          astounding biodiversity. We are dedicated to educating our guests
          about the extraordinary emerald tree boa and its vital role in the
          rainforest ecosystem.
        </p>
        <p>
          Our aim is to foster a sense of adventure and excitement as we guide
          you through this enchanting world, introducing you to the native
          peoples and their ancient wisdom, while emphasizing the importance of
          conservation and sustainable practices.
        </p>
      </div>
    </div>
  );
}

export default Mission;
