import React from "react";
import Moon1 from "../images/moons/moon1.svg";
import Moon2 from "../images/moons/moon2.svg";
import Moon3 from "../images/moons/moon3.svg";
import Moon4 from "../images/moons/moon4.svg";
import Moon5 from "../images/moons/moon5.svg";
import Moon6 from "../images/moons/moon6.svg";
import Moon7 from "../images/moons/moon7.svg";
import Moon8 from "../images/moons/moon8.svg";
import Moon9 from "../images/moons/moon9.svg";
import Moon10 from "../images/moons/moon10.svg";
import Moon11 from "../images/moons/moon11.svg";

function Dates() {
  return (
    <div className="dates">
      <h3>Travel dates are planned around the new moon.</h3>
      <h4>
        Trips range from 14-19 days depending on flight and boat schedules.
      </h4>
      <h3>Approximate upcoming 2024 tour dates:</h3>

      <h3 className="full"> March 3-16 FILLED</h3>
      <div className="moon-sweep">
        <div className="moon-card">
          <img src={Moon1} className="moon" alt="Moon icon"></img>
          <div className="caption">3</div>
        </div>
        <div className="moon-card">
          <img src={Moon2} className="moon" alt="Moon icon"></img>
          <div className="caption">4</div>
        </div>
        <div className="moon-card">
          <img src={Moon3} className="moon" alt="Moon icon"></img>
          <div className="caption">5</div>
        </div>
        <div className="moon-card">
          <img src={Moon4} className="moon" alt="Moon icon"></img>
          <div className="caption">6</div>
        </div>
        <div className="moon-card">
          <img src={Moon5} className="moon" alt="Moon icon"></img>
          <div className="caption">7</div>
        </div>
        <div className="moon-card">
          <img src={Moon5} className="moon" alt="Moon icon"></img>
          <div className="caption">8</div>
        </div>
        <div className="moon-card">
          <img src={Moon6} className="moon" alt="Moon icon"></img>
          <div className="caption">9</div>
        </div>
        <div className="moon-card">
          <img src={Moon6} className="moon" alt="Moon icon"></img>
          <div className="caption">10</div>
        </div>
        <div className="moon-card">
          <img src={Moon7} className="moon" alt="Moon icon"></img>
          <div className="caption">11</div>
        </div>
        <div className="moon-card">
          <img src={Moon7} className="moon" alt="Moon icon"></img>
          <div className="caption">12</div>
        </div>
        <div className="moon-card">
          <img src={Moon8} className="moon" alt="Moon icon"></img>
          <div className="caption">13</div>
        </div>
        <div className="moon-card">
          <img src={Moon9} className="moon" alt="Moon icon"></img>
          <div className="caption">14</div>
        </div>
        <div className="moon-card">
          <img src={Moon10} className="moon" alt="Moon icon"></img>
          <div className="caption">15</div>
        </div>
        <div className="moon-card">
          <img src={Moon11} className="moon" alt="Moon icon"></img>
          <div className="caption">16</div>
        </div>
      </div>

      <h3> April 2-15 2 spots left!</h3>
      <div className="moon-sweep">
        <div className="moon-card">
          <img src={Moon1} className="moon" alt="Moon icon"></img>
          <div className="caption">2</div>
        </div>
        <div className="moon-card">
          <img src={Moon2} className="moon" alt="Moon icon"></img>
          <div className="caption">3</div>
        </div>
        <div className="moon-card">
          <img src={Moon3} className="moon" alt="Moon icon"></img>
          <div className="caption">4</div>
        </div>
        <div className="moon-card">
          <img src={Moon4} className="moon" alt="Moon icon"></img>
          <div className="caption">5</div>
        </div>
        <div className="moon-card">
          <img src={Moon5} className="moon" alt="Moon icon"></img>
          <div className="caption">6</div>
        </div>
        <div className="moon-card">
          <img src={Moon5} className="moon" alt="Moon icon"></img>
          <div className="caption">7</div>
        </div>
        <div className="moon-card">
          <img src={Moon6} className="moon" alt="Moon icon"></img>
          <div className="caption">8</div>
        </div>
        <div className="moon-card">
          <img src={Moon6} className="moon" alt="Moon icon"></img>
          <div className="caption">9</div>
        </div>
        <div className="moon-card">
          <img src={Moon7} className="moon" alt="Moon icon"></img>
          <div className="caption">10</div>
        </div>
        <div className="moon-card">
          <img src={Moon7} className="moon" alt="Moon icon"></img>
          <div className="caption">11</div>
        </div>
        <div className="moon-card">
          <img src={Moon8} className="moon" alt="Moon icon"></img>
          <div className="caption">12</div>
        </div>
        <div className="moon-card">
          <img src={Moon9} className="moon" alt="Moon icon"></img>
          <div className="caption">13</div>
        </div>
        <div className="moon-card">
          <img src={Moon10} className="moon" alt="Moon icon"></img>
          <div className="caption">14</div>
        </div>
        <div className="moon-card">
          <img src={Moon11} className="moon" alt="Moon icon"></img>
          <div className="caption">15</div>
        </div>
      </div>

      <h3 className="full"> May 1-14 FILLED</h3>
      <div className="moon-sweep">
        <div className="moon-card">
          <img src={Moon1} className="moon" alt="Moon icon"></img>
          <div className="caption">1</div>
        </div>
        <div className="moon-card">
          <img src={Moon2} className="moon" alt="Moon icon"></img>
          <div className="caption">2</div>
        </div>
        <div className="moon-card">
          <img src={Moon3} className="moon" alt="Moon icon"></img>
          <div className="caption">3</div>
        </div>
        <div className="moon-card">
          <img src={Moon4} className="moon" alt="Moon icon"></img>
          <div className="caption">4</div>
        </div>
        <div className="moon-card">
          <img src={Moon5} className="moon" alt="Moon icon"></img>
          <div className="caption">5</div>
        </div>
        <div className="moon-card">
          <img src={Moon5} className="moon" alt="Moon icon"></img>
          <div className="caption">6</div>
        </div>
        <div className="moon-card">
          <img src={Moon6} className="moon" alt="Moon icon"></img>
          <div className="caption">7</div>
        </div>
        <div className="moon-card">
          <img src={Moon6} className="moon" alt="Moon icon"></img>
          <div className="caption">8</div>
        </div>
        <div className="moon-card">
          <img src={Moon7} className="moon" alt="Moon icon"></img>
          <div className="caption">9</div>
        </div>
        <div className="moon-card">
          <img src={Moon7} className="moon" alt="Moon icon"></img>
          <div className="caption">10</div>
        </div>
        <div className="moon-card">
          <img src={Moon8} className="moon" alt="Moon icon"></img>
          <div className="caption">11</div>
        </div>
        <div className="moon-card">
          <img src={Moon9} className="moon" alt="Moon icon"></img>
          <div className="caption">12</div>
        </div>
        <div className="moon-card">
          <img src={Moon10} className="moon" alt="Moon icon"></img>
          <div className="caption">13</div>
        </div>
        <div className="moon-card">
          <img src={Moon11} className="moon" alt="Moon icon"></img>
          <div className="caption">14</div>
        </div>
      </div>

      <h3> June 1-14 Open Spots!</h3>
      <div className="moon-sweep">
        <div className="moon-card">
          <img src={Moon1} className="moon" alt="Moon icon"></img>
          <div className="caption">1</div>
        </div>
        <div className="moon-card">
          <img src={Moon2} className="moon" alt="Moon icon"></img>
          <div className="caption">2</div>
        </div>
        <div className="moon-card">
          <img src={Moon3} className="moon" alt="Moon icon"></img>
          <div className="caption">3</div>
        </div>
        <div className="moon-card">
          <img src={Moon4} className="moon" alt="Moon icon"></img>
          <div className="caption">4</div>
        </div>
        <div className="moon-card">
          <img src={Moon5} className="moon" alt="Moon icon"></img>
          <div className="caption">5</div>
        </div>
        <div className="moon-card">
          <img src={Moon5} className="moon" alt="Moon icon"></img>
          <div className="caption">6</div>
        </div>
        <div className="moon-card">
          <img src={Moon6} className="moon" alt="Moon icon"></img>
          <div className="caption">7</div>
        </div>
        <div className="moon-card">
          <img src={Moon6} className="moon" alt="Moon icon"></img>
          <div className="caption">8</div>
        </div>
        <div className="moon-card">
          <img src={Moon7} className="moon" alt="Moon icon"></img>
          <div className="caption">9</div>
        </div>
        <div className="moon-card">
          <img src={Moon7} className="moon" alt="Moon icon"></img>
          <div className="caption">10</div>
        </div>
        <div className="moon-card">
          <img src={Moon8} className="moon" alt="Moon icon"></img>
          <div className="caption">11</div>
        </div>
        <div className="moon-card">
          <img src={Moon9} className="moon" alt="Moon icon"></img>
          <div className="caption">12</div>
        </div>
        <div className="moon-card">
          <img src={Moon10} className="moon" alt="Moon icon"></img>
          <div className="caption">13</div>
        </div>
        <div className="moon-card">
          <img src={Moon11} className="moon" alt="Moon icon"></img>
          <div className="caption">14</div>
        </div>
      </div>
    </div>
  );
}

export default Dates;
